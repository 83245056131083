function ItemCheckboxWeek(ItemCheckboxWeek) {
    // const [showResults, setShowResults] = React.useState(false)
    // const onchange = () => setShowResults(true)

    return (
        <div className="form-check">
            <div className="row mt-2">
                <div className="col-sm-4">
                    <input className="form-check-input"
                        type="checkbox"
                        id={ItemCheckboxWeek.id + "_chb"}
                        name={ItemCheckboxWeek.name}
                        // checked={ItemCheckboxWeek.checked}
                        value={ItemCheckboxWeek.id}
                    />
                    <label className="form-check-label" htmlFor={ItemCheckboxWeek.id + "_chb"}>{ItemCheckboxWeek.text}</label>
                </div>

                <div className="col-sm-4">
                    <label htmlFor={ItemCheckboxWeek.id + "_start"} className="form-label">Start Time:</label>
                    <select defaultValue="0900" id={ItemCheckboxWeek.id + "_start"} name={ItemCheckboxWeek.id + "_start"} className="form-select" required>
                        <ItemSelectRowLoop />
                    </select>
                </div>
                <div className="col-sm-4">
                    <label htmlFor={ItemCheckboxWeek.id + "_end"} className="form-label">End Time:</label>
                    <select defaultValue="1700" id={ItemCheckboxWeek.id + "_end"} name={ItemCheckboxWeek.id + "_end"} className="form-select" required>
                        <ItemSelectRowLoop />
                    </select>
                </div>
            </div>
        </div>
    )
}

function ItemSelectRowLoop() {
    var arr = [];

    for (let i = 0; i <= 23; i++) {
        i = i > 9 ? i : '0' + i;
        arr.push(<option key={i + "00"} value={i + "00"}>{i}00</option>)
        arr.push(<option key={i + "30"} value={i + "30"}>{i}30</option>)
    }

    return arr;
}

export default ItemCheckboxWeek;