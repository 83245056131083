import RowInputText from '../components/row/RowText';
import ItemCheckbox from '../components/row/RowCheckbox';
import ItemCheckboxtext from '../components/row/RowCheckboxText';

import CustInfo from '../components/CustInfo';
import ProjPro from '../components/ProjPro';
import AddFeat from '../components/AddFeat';

const row_class = "mb-3 row justify-content-md-center";
const col_block_class = "col-sm-6 border border-primary shadow p-3 bg-white rounded"

function Saform() {

    return (
        <form action="formdb/form.php" method="post">
            <fieldset>
                <div className={row_class}>
                    <div className="col-sm-6">
                        <h1>Questionaire : <br />Fast tract for quotation </h1>
                    </div>
                </div>

                <hr />

                <CustInfo row_class={row_class} col_block_class={col_block_class} />

                <hr />

                <div className={row_class}>Tel-sales/Marketing project proposal</div>

                <div className={row_class}>
                    <div className={col_block_class}>
                        <span id="operation_mode" className="form-label">1. Operation Mode:</span>
                        <ItemCheckbox id="research" name="chbom[]" text="Market Research/Consumer Survey" />
                        <ItemCheckbox id="appointment" name="chbom[]" text="Appointment schedule" />
                        <ItemCheckbox id="review" name="chbom[]" text="Customer data review" />
                        <ItemCheckbox id="rsvp" name="chbom[]" text="Event management/RSVP" />

                        <ItemCheckboxtext id="other" name="chbom[]" text="Other :" placeholder='Input text' />

                    </div>
                </div>

                <ProjPro row_class={row_class} col_block_class={col_block_class} />

                <div className={row_class}>
                    <div className={col_block_class}>
                        <RowInputText type='number' id='no_call_client' text='5. Number of target call receipient provided by you' placeholder='Input number' required='required' />
                    </div>
                </div>
                <div className={row_class}>
                    <div className={col_block_class}>
                        <RowInputText type='number' id='no_call_us' text='6. Number of target call receipient expected from iEnterprise' placeholder='Input number' required='required' />
                    </div>
                </div>

                <hr />

                <div className={row_class}>KPI</div>

                <div className={row_class}>
                    <div className={col_block_class}>
                        <RowInputText type='number' id='kpi_proj' text='7. KPI - Target successful case for the whole project' placeholder='Input number' required='' />
                    </div>
                </div>
                <div className={row_class}>
                    <div className={col_block_class}>
                        <RowInputText type='number' id='kpi_month' text='8. KPI - Target successful case per month' placeholder='Input number' required='' />
                    </div>
                </div>
                <div className={row_class}>
                    <div className={col_block_class}>
                        <RowInputText type='number' id='kpi_week' text='9. KPI - Target successful case per week' placeholder='Input number' required='' />
                    </div>
                </div>
                <div className={row_class}>
                    <div className={col_block_class}>
                        <RowInputText type='text' id='kpi_text' text='10. KPI - Other' placeholder='Input text' required='' />
                    </div>
                </div>

                {/* <div className={row_class}>
            <RowInputText type='number' id='no_call' text='4.Current and Expected number of calls/enquiries per day' placeholder='Number of call' required='required' />
          </div> */}
                <div className={row_class}>
                    <div className={col_block_class}>
                        <RowInputText type='number' id='no_call' text='11.Current and Expected Number of Calls per day' placeholder='Number of call (if Unknow please input 0)' required='required' />
                    </div>
                </div>
                <div className={row_class}>
                    <div className={col_block_class}>
                        <RowInputText type='number' id='no_call_succ_min' text='12. Historical average duration of each successful call (in minutes)' placeholder='Minute per call' required='required' />
                    </div>
                </div>
                <div className={row_class}>
                    <div className={col_block_class}>
                        <RowInputText type='number' id='no_call_unsucc_min' text='13. Historical average duration of each unsuccessful call (in minutes)' placeholder='Minute per call' required='required' />
                    </div>
                </div>

                <hr />


                <AddFeat row_class={row_class} col_block_class={col_block_class} qnumber={[14, 15, 16, 17]} />


                <div className={row_class}>
                    <div className="col-sm-6">
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </div>
            </fieldset>
            <input type="hidden" id="formtype" name="formtype" value="tel" />
        </form>
    );
}

export default Saform;
