import { useState } from 'react';
function ItemRadioText(ItemRadio) {
  const [radiotext, setradiotext] = useState("");
  return (
    // <div className="form-check form-check-inline">
    <div className="form-check form-check-inline">
      <input className="form-check-input"
        type="radio"
        name={ItemRadio.name}
        id={ItemRadio.id}
        value={radiotext}
      />
      <label className="form-check-label" htmlFor={ItemRadio.id}>Please input your Duration:</label>

      <div className="input-group input-group-sm">
        <input
          name={ItemRadio.name + "_text"}
          type="number" min="1"
          className="form-control"
          placeholder={ItemRadio.placeholder}
          aria-label={ItemRadio.placeholder}
          aria-describedby={ItemRadio.id + "text"}
          onChange={(e) => setradiotext(e.target.value)}
        />
        <span className="input-group-text" id={ItemRadio.id + "text"}>{ItemRadio.text}</span>
      </div>
    </div>
  )
}
export default ItemRadioText;