function RowInputText(row_data) {
    return (
        <div>
            <label htmlFor={row_data.id} className="form-label">{row_data.text}{(row_data.required === "required" ? <span style={{ color: "red" }}> * </span> : '')}:</label>
            <input className="form-control"
                id={row_data.id}
                name={row_data.id}
                type={row_data.type}
                placeholder={row_data.placeholder}
                aria-label={row_data.placeholder}
                required={row_data.required}
                min={(row_data.type === "number" ? '0' : '')}
            />
        </div>
    )
}
export default RowInputText;