import RowInputText from '../components/row/RowText';
import ItemCheckbox from '../components/row/RowCheckbox';
import ItemCheckboxtext from '../components/row/RowCheckboxText';
import ItemSelectBusiness from '../components/row/RowSelectBusiness';
import ItemRadio from '../components/row/RowItemRadio';

import CustInfo from '../components/CustInfo';

const row_class = "mb-3 row justify-content-md-center";
const col_block_class = "col-sm-6 border border-primary shadow p-3 bg-white rounded"

function Webform() {

  return (
    <form action="formdb/webform.php" method="post">
      <fieldset>
        <div className={row_class}>
          <div className="col-sm-6">
            <h1>Questionaire : <br />Fast tract for quotation </h1>
          </div>
        </div>

        <hr />
        <div className={row_class}>
          <div className={col_block_class}>
            <label style={{ color: "red" }}>We can help to apply for government funding on this project, if needed.</label>
          </div>
        </div>
        <CustInfo row_class={row_class} col_block_class={col_block_class} />

        {/* <div className={row_class}>
          <div className={col_block_class}>
            <RowInputText type='text' id='position' text='Position' placeholder='Your position' />
          </div>
        </div> */}

        <div className={row_class}>
          <div className={col_block_class}>
            <RowInputText type='text' id='url' text='URL of Company' placeholder='Company URL' />
          </div>
        </div>

        {/* <div className={row_class}>
          <div className={col_block_class}>
            <RowInputText type='text' id='address' text='Address' placeholder='your company address' />
          </div>
        </div> */}

        <div className={row_class}>
          <div className={col_block_class}>
            <ItemSelectBusiness id='business_nature' text='Business Nature' />
            <RowInputText type='text' id='business_others' text='Others, please specify' placeholder='please specify' />
          </div>
        </div>

        <div className={row_class}>
          <div className={col_block_class}>
            <span id="services" className="form-label">What services are you interested in?</span>

            {/* <ItemCheckbox id="web_accessibility_check_12" name="chbom[]" text="Web Accessibility Check (1year per month)" />
            <ItemCheckbox id="web_accessibility_check_1" name="chbom[]" text="Web Accessibility Check (1 time)" />
            <ItemCheckbox id="web_accessibility_modify" name="chbom[]" text="Web Accessibility modify" /> */}

            <ItemCheckbox id="web_accessibility_assessment" name="chbom[]" text="Web Accessibility Assessment" />

            <ItemCheckbox id="websys" name="chbom[]" text="Web-based System" />
            <ItemCheckbox id="webdesign" name="chbom[]" text="Web design (Include Accessibility)" />
            {/* <ItemCheckbox id="crm" name="chbom[]" text="CRM" /> */}
            <ItemCheckbox id="mobile" name="chbom[]" text="Mobile Apps Development" />
            <ItemCheckbox id="graphic" name="chbom[]" text="Graphic design / logo and corporate identity" />
            <ItemCheckboxtext id="other" name="chbom[]" text="Other :" placeholder='please specify' />
          </div>
        </div>

        <div className={row_class}>
          <div className={col_block_class}>
            <label htmlFor="budget" className="form-label">Estimated budget range (HK$)</label>
            <br />
            <ItemRadio name="budget" id="budget1" text="under $50,000" value="5" />
            <ItemRadio name="budget" id="budget2" text="$50,000 to $100,000" value="10" />
            <ItemRadio name="budget" id="budget3" text="$100,000 to $200,000" value="20" />
            <ItemRadio name="budget" id="budget4" text="$200,000 to $300,000" value="30" />
            <ItemRadio name="budget" id="budget5" text="above $300,000" value="31" />
          </div>
        </div>

        <div className={row_class}>
          <div className={col_block_class}>
            <label htmlFor="fund" className="form-label">Funding type</label>
            <br />
            <ItemRadio name="fund" id="fund1" text="Internal funding" value="internal" />
            <ItemRadio name="fund" id="fund2" text="External funding" value="external" />
            <small style={{ color: "red" }}>We can help to apply for government funding on this project, if needed.</small>
          </div>
        </div>

        <hr />

        {/* <div className={row_class}>Project Details</div>
        <div className={row_class}>
          <div className={col_block_class}>
            <label for="details">About this project</label>
            <textarea class="form-control" id="details" name="details" rows="6" placeholder='for example: scope of the project, time frame..etc'></textarea>
          </div>
        </div> */}

        {/* <hr /> */}

        {/* <div className={row_class}>Additional Information</div>
        <div className={row_class}>
          <div className={col_block_class}>
            <RowInputText type='text' id='additional' text='Where did you hear about us?' placeholder='please specify' />
          </div>
        </div> */}

        {/* <hr /> */}

        <div className={row_class}>
          <div className="col-sm-6">
            <button type="submit" className="btn btn-primary">Submit</button>
          </div>
        </div>
      </fieldset>
      {/* <input type="hidden" id="formtype" name="formtype" value="cs" /> */}
    </form>
  );
}

export default Webform;
