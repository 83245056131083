import ItemCheckbox from './row/RowCheckbox';
import ItemRadio from './row/RowItemRadio';
import RowInputText from './row/RowText';


function AddFeat(css_class) {
    return (
        <div>
            <div className={css_class.row_class}>Additional Features</div>
            <div className={css_class.row_class}>
                <div className={css_class.col_block_class}>
                    <label htmlFor="record_voice" className="form-label">{css_class.qnumber[0]}. Recording voice call requirement (sampling or full population):</label>
                    <br />
                    <ItemRadio name="record_voice" id="record_voice1" text="Full population" value="full-population" />
                    <ItemRadio name="record_voice" id="record_voice2" text="Sampling" value="sampling" />
                </div>
            </div>
            <div className={css_class.row_class}>
                <div className={css_class.col_block_class}>
                    <label htmlFor="report_req" className="form-label">{css_class.qnumber[1]}. Reporting requirement (daily/weekly/monthly report):</label>
                    <br />
                    <ItemRadio name="report_req" id="report_req1" text="Daily" value="daily" />
                    <ItemRadio name="report_req" id="report_req2" text="Weekly" value="weekly" />
                    <ItemRadio name="report_req" id="report_req3" text="Monthly" value="monthly" />
                </div>
            </div>
            <div className={css_class.row_class}>
                <div className={css_class.col_block_class}>
                    <span id="add_dash" className="form-label">{css_class.qnumber[2]}. Dashboard/Analytics requirement, if any:</span>
                    <ItemCheckbox id="add_dash_true" name="add_dash" text="Yes" />
                </div>
            </div>
            <div className={css_class.row_class}>
                <div className={css_class.col_block_class}>
                    <RowInputText type='text' id='other_req' text={css_class.qnumber[3] + '. Others Requirement'} placeholder='requirement' />
                </div>
            </div>
        </div>
    )
}
export default AddFeat;