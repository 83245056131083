import RowInputText from '../components/row/RowText';
import ItemCheckbox from '../components/row/RowCheckbox';

import CustInfo from '../components/CustInfo';
import ProjPro from '../components/ProjPro';
import AddFeat from '../components/AddFeat';

const row_class = "mb-3 row justify-content-md-center";
const col_block_class = "col-sm-6 border border-primary shadow p-3 bg-white rounded"

function Csform() {

  return (
      <form action="formdb/form.php" method="post">
        <fieldset>
          <div className={row_class}>
            <div className="col-sm-6">
              <h1>Questionaire : <br />Fast tract for quotation </h1>
            </div>
          </div>

          <hr />

          <CustInfo row_class={row_class} col_block_class={col_block_class} />

          <hr />

          <div className={row_class}>Customers Service project proposal</div>

          <div className={row_class}>
            <div className={col_block_class}>
              <span id="operation_mode" className="form-label">1. Operation Mode:</span>
              <ItemCheckbox id="enquiry" name="chbom[]" text="Customers Service - Enquiry" />
              <ItemCheckbox id="complaints" name="chbom[]" text="Customers Service - Complaints" />
              <ItemCheckbox id="sales" name="chbom[]" text="After sales service" />
              <ItemCheckbox id="emergency" name="chbom[]" text="Hotline - Emergency Service" />
              {/* <ItemCheckbox id="research" name="chbom[]" text="Market Research/Consumer Survey" /> */}
            </div>
          </div>

          <ProjPro row_class={row_class} col_block_class={col_block_class} />

          <hr />

          <div className={row_class}>KPI</div>


          <div className={row_class}>
            <div className={col_block_class}>
              <RowInputText type='number' id='no_call_in_sec' text='5. KPI - Incoming Call maximum ring time (sec)' placeholder='Input sec' required='required' />
            </div>
          </div>

          <div className={row_class}>
            <div className={col_block_class}>
              <RowInputText type='number' id='no_call' text='6.Current and Expected number of calls/enquiries per day' placeholder='Number of call' required='required' />
            </div>
          </div>

          <div className={row_class}>
            <div className={col_block_class}>
              <RowInputText type='number' id='no_call_min' text='7. Historical average duration of each call (in minute)' placeholder='Minute per call' required='required' />
            </div>
          </div>


          <div className={row_class}>
            <div className={col_block_class}>
              <RowInputText type='number' id='no_call_percent' text='8. Tolerance level of no answered calls (%)' placeholder='input number e.g 10% is 10' required='required' />
            </div>
          </div>

          <hr />

          <AddFeat row_class={row_class} col_block_class={col_block_class} qnumber={[9, 10, 11, 12]} />

          <div className={row_class}>
            <div className="col-sm-6">
              <button type="submit" className="btn btn-primary">Submit</button>
            </div>
          </div>
        </fieldset>
        <input type="hidden" id="formtype" name="formtype" value="cs" />
      </form>
  );
}

export default Csform;
