import { useState } from 'react';

function ItemCheckboxtext(ItemCheckbox) {
    const [checktext, setchecktext] = useState("");
    return (
        <div className="form-check">
            <input className="form-check-input"
                type="checkbox"
                id={ItemCheckbox.id + "_chb"}
                name={ItemCheckbox.name}
                // checked={ItemCheckbox.checked}
                value={"other_" + checktext}
            />
            <label className="form-check-label" htmlFor={ItemCheckbox.id + "_chb"}>{ItemCheckbox.text}</label>


            <div className="input-group input-group-sm">
                <input className="form-control"
                    name={ItemCheckbox.id + "_chb_text"}
                    type="text"
                    placeholder={ItemCheckbox.placeholder}
                    aria-label={ItemCheckbox.placeholder}
                    aria-describedby={ItemCheckbox.id + "_chb"}
                    onChange={(e) => setchecktext(e.target.value)}
                />
            </div>
        </div>
    )
}
export default ItemCheckboxtext;