function RowSelectBusiness(row_select) {
    return (
        <div>
            <label htmlFor={row_select.id} className="form-label">{row_select.text}</label>
            <select id={row_select.id} name={row_select.id} className="form-select">
                {/* <option value="1" key="1">One</option>
                <option value="2" key="2">Two</option>
                <option value="3" key="3">Three</option> */}
                <option value="">----- Please select -----</option>
                <option value="1" key="1">Accounting</option>
                <option value="2" key="2">Advertising &amp; Design</option>
                <option value="3" key="3">Association</option>
                <option value="4" key="4">Banking</option>
                <option value="5" key="5">Consulting firm</option>
                <option value="6" key="6">Computer &amp; IT related</option>
                <option value="7" key="7">Cultural</option>
                <option value="8" key="8">Electronics</option>
                <option value="9" key="9">Engineering</option>
                <option value="10" key="10">Entertainment</option>
                <option value="11" key="11">Fashion</option>
                <option value="12" key="12">Finance</option>
                <option value="13" key="13">Government Departments</option>
                <option value="14" key="14">Healthcare</option>
                <option value="15" key="15">Hotel &amp; Travel</option>
                <option value="16" key="16">Insurance</option>
                <option value="17" key="17">Jewellery</option>
                <option value="18" key="18">Law firm</option>
                <option value="19" key="19">Manufacturer</option>
                <option value="20" key="20">Multi-discipline corporation</option>
                <option value="21" key="21">Non-profit organization</option>
                <option value="22" key="22">NGO</option>
                <option value="23" key="23">P.R &amp; Marketing</option>
                <option value="24" key="24">Public Utility</option>
                <option value="25" key="25">Real Estate</option>
                <option value="26" key="26">Retail</option>
                <option value="27" key="27">Shipping</option>
                <option value="28" key="28">Supply Chain &amp; Logistics</option>
                <option value="29" key="29">Trading</option>
                <option value="30" key="30">Watch and Clock</option>
            </select>
        </div>
    )
}
export default RowSelectBusiness;