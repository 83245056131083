import RowInputText from './row/RowText';

function CustInfo(css_class) {
    return (
        <div>
            <div className={css_class.row_class}>Customer Informaton</div>
            <div className={css_class.row_class}>
                <div className={css_class.col_block_class}>
                    <RowInputText type='text' id='company' text='Company Name' placeholder='Input Company Name' required='required' />
                </div>
            </div>
            <div className={css_class.row_class}>
                <div className={css_class.col_block_class}>
                    <RowInputText type='text' id='incharge' text='Person in charge/contact person' placeholder='Input Person in charge' required='required' />
                </div>
            </div>
            <div className={css_class.row_class}>
                <div className={css_class.col_block_class}>
                    <RowInputText type='tel' id='tele' text='Tel no./Mobile' placeholder='Input Telephone/Mobile' required='required' />
                </div>
            </div>
            <div className={css_class.row_class}>
                <div className={css_class.col_block_class}>
                    <RowInputText type='email' id='mail' text='Email' placeholder='Input Email' required='required' />
                </div>
            </div>
        </div>
    )
}
export default CustInfo;