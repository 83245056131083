function ItemCheckbox(ItemCheckbox) {
    return (
        <div className="form-check">
            <input className="form-check-input"
                type="checkbox"
                id={ItemCheckbox.id + "_chb"}
                name={ItemCheckbox.name}
                // checked={ItemCheckbox.checked}
                value={ItemCheckbox.id}
            />
            <label className="form-check-label" htmlFor={ItemCheckbox.id + "_chb"}>{ItemCheckbox.text}</label>
        </div>
    )
}
export default ItemCheckbox;