import ItemCheckbox from './row/RowCheckbox';
import ItemCheckboxWeek from './row/RowCheckboxWeek';
import ItemRadio from './row/RowItemRadio';
import ItemRadioText from './row/RowItemRadioText';

function ProjPro(css_class) {
    return (
        <div>
            <div className={css_class.row_class}>
                <div className={css_class.col_block_class}>
                    <span id="operation_mode" className="form-label">2. Service Language:</span>
                    <ItemCheckbox id="zh" name="chblang[]" text="Cantonese" />
                    <ItemCheckbox id="eng" name="chblang[]" text="English" />
                    <ItemCheckbox id="mad" name="chblang[]" text="Mandarin" />
                </div>
            </div>

            <div className={css_class.row_class}>
                <div className={css_class.col_block_class}>
                    <label htmlFor="duration" className="form-label">3. Project Duration:</label>
                    <br />
                    {/* input month */}

                    <ItemRadio name="duration" id="duration1" text="1 Month" value="1" />
                    <ItemRadio name="duration" id="duration2" text="6 Months" value="6" />
                    <ItemRadio name="duration" id="duration3" text="1 Year" value="12" />

                    <ItemRadioText name="duration" id="duration4" text="Month" value="99" placeholder='no. for month' />

                </div>
            </div>

            <div className={css_class.row_class}>
                <div className={css_class.col_block_class}>
                    <span id="work_per_week" className="form-label">4. Target working days per week (5 days, 7 days):</span>
                    {/* <ItemCheckboxWeek id="mon" name="chbweek[]" text="Monday" checked="checked" />
              <ItemCheckboxWeek id="tue" name="chbweek[]" text="Tuesday" checked="checked" />
              <ItemCheckboxWeek id="wed" name="chbweek[]" text="Wednesday" checked="checked" />
              <ItemCheckboxWeek id="thu" name="chbweek[]" text="Thursday" checked="checked" />
              <ItemCheckboxWeek id="fri" name="chbweek[]" text="Friday" checked="checked" />
              <ItemCheckboxWeek id="sat" name="chbweek[]" text="Saturday" />
              <ItemCheckboxWeek id="sun" name="chbweek[]" text="Sunday" /> */}

                    <ItemCheckboxWeek id="mon" name="chbweek[]" text="Monday" />
                    <ItemCheckboxWeek id="tue" name="chbweek[]" text="Tuesday" />
                    <ItemCheckboxWeek id="wed" name="chbweek[]" text="Wednesday" />
                    <ItemCheckboxWeek id="thu" name="chbweek[]" text="Thursday" />
                    <ItemCheckboxWeek id="fri" name="chbweek[]" text="Friday" />
                    <ItemCheckboxWeek id="sat" name="chbweek[]" text="Saturday" />
                    <ItemCheckboxWeek id="sun" name="chbweek[]" text="Sunday" />

                    {/* <ItemCheckbox id="mon" name="chbweek[]" text="Monday" />

              <ItemCheckbox id="mad" name="chbweek[]" text="Mandarin" /> */}
                </div>
            </div>


            <div className={css_class.row_class}>
                    <div className="col-sm-6">
                        <label className="form-label">#### Hours Per Month ( 22 working days (9am-6pm)) = 176 hours</label>
                    </div>
                </div>

        </div>
    )
}
export default ProjPro;