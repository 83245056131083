import { Routes, Route } from "react-router-dom";
import Saform from "./pages/Saform";
import Csform from "./pages/Csform";
import Webform from "./pages/Webform";

function App() {
  return (
    <main id="content" className="container">
       <Routes>
        <Route path="/" element={<Csform />} />
        <Route path="csform" element={<Csform />} />
        <Route path="saform" element={<Saform />} />
        <Route path="webform" element={<Webform />} />
      </Routes>
    </main>
  );
}

export default App;
